<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2 pl-5 elevation-0">

          <v-tabs v-model="tab" @change="tabChanged">
            <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <GoogleAccount v-if="tab == 0"></GoogleAccount>
            </v-tab-item>
            <v-tab-item>
              <GoogleCampaigns v-if="tab == 1"></GoogleCampaigns>
            </v-tab-item>
            <v-tab-item>
              <GoogleKeywords v-if="tab == 2"></GoogleKeywords>
            </v-tab-item>
            <v-tab-item>
              <GoogleRSAs v-if="tab == 3"></GoogleRSAs>
            </v-tab-item>
            <v-tab-item>
              <GoogleExtensions v-if="tab == 4"></GoogleExtensions>
            </v-tab-item>
          </v-tabs-items>

      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Googlebase',

  data () {
    return {
      tab: null,
      items: ['Account', 'Campaigns', 'Keywords', 'RSAs', 'Extensions'],
      lsTab: 'googleBase_tab',
    }
  },

  components: {
    GoogleAccount: () => import('@/components/contentCreator/GoogleAccount.vue'),
    GoogleCampaigns: () => import('@/components/contentCreator/GoogleCampaigns.vue'),
    GoogleExtensions: () => import('@/components/contentCreator/GoogleExtensions.vue'),
    GoogleKeywords: () => import('@/components/contentCreator/GoogleKeywords.vue'),
    GoogleRSAs: () => import('@/components/contentCreator/GoogleRSAs.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>